
<template>
  <BackofficeBase :loader_prop="loader_prop">
    <div class="item_list">
      <div>
        <router-link
          :to="{
            path: '/backoffice/distribution_point_edit',
            query: { id: 0 },
          }"
          >הקמת נקודת חלוקה</router-link
        >
      </div>
      <!-- <table class ="distribution_point_table"> -->
      <!-- <tr>
          <th>תיבות תקולות </th>
          <th>ימים לשימוש</th>
          <th>מצב סוללה</th>
          <th>תפוסה 40*11</th>
          <th>תפוסה 40*25</th>
          <th>תפוסה 40*45</th>
          <th>יחידה</th>
          <th>שם נקודת החלוקה</th>
          <th>מידע כללי</th>
        </tr>
        <tr v-for="distribution_point in view_data" :key="distribution_point.id" >
          <td> {{distribution_point.faulty_boxes}} </td>
          <td>{{distribution_point.several_days}}</td>
          <td>{{distribution_point.battery_status}}
              <p v-if = "distribution_point.battery_status == null">אין מידע</p>
          </td>
          <td>{{distribution_point.small_catchs}}/{{distribution_point.small_amount}}</td>
          <td>{{distribution_point.medium_catchs}}/{{distribution_point.medium_amount}}</td>
          <td>{{distribution_point.large_catchs}}/{{distribution_point.large_amount}}</td>
          <td>{{distribution_point.unit_name}}
              <p v-if = "distribution_point.unit_name == null">אין יחידות לנקודה זו</p>
           </td>
          <td>
              <router-link :to="{path : '/backoffice/distribution_point', query : {'id' : distribution_point.id}}">{{distribution_point.name}}</router-link> 
          </td>
          <td>{{distribution_point.occupancy_warning}}</td>
        </tr> -->

      <!-- </table> -->
      <div v-for="point in view_data" :key="point.id">
        <h1>{{ point.name }}</h1>
        <router-link
          :to="{
            path: '/backoffice/distribution_point',
            query: { id: point.id },
          }"
          >לניהול היחידה</router-link
        >
        <div v-if="point.units_data.length != 0">
          <table class="distribution_point_table">
            <tr>
              <th>תיבות תקולות</th>
              <th>ימים לשימוש</th>
              <th>מצב סוללה</th>
              <th>תפוסה 40*11</th>
              <th>תפוסה 40*25</th>
              <th>תפוסה 40*45</th>
              <th>שם היחידה</th>
              <th>מידע כללי</th>
            </tr>
            <tr v-for="unit in point.units_data" :key="unit.units_id">
              <td>אין מידע</td>
              <td>אין מידע</td>
              <td>
                <p>אין מידע</p>
              </td>
              <td>{{ unit.small_catchs }}/{{ unit.small_amount }}</td>
              <td>{{ unit.medium_catchs }}/{{ unit.medium_amount }}</td>
              <td>{{ unit.large_catchs }}/{{ unit.large_amount }}</td>
              <td>
                {{ unit.unit_name }}
                <p v-if="unit.unit_name == null">אין יחידות לנקודה זו</p>
              </td>
              <td>
                <router-link
                  :to="{
                    path: '/backoffice/distribution_point',
                    query: { id: unit.id },
                  }"
                  >{{ unit.name }}</router-link
                >
              </td>
              <!-- <td>{{distribution_point.occupancy_warning}}</td> -->
            </tr>
          </table>
        </div>
        <div v-if="point.units_data.length == 0">
          <h3>אין יחידות לתיבה זו</h3>
        </div>
      </div>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: "BackofficeDistributionPoints",
  components: {
    BackofficeBase,
  },
  data() {
    return {
      loader_prop: true,
      view_data: [],
    };
  },
  async mounted() {
    let backendModel = new BackendModel();
    let view_data = await backendModel.backendRequest(
      "/Api/service/backoffice/get_distribution_points",
      {}
    );
    // this.distribution_points = distribution_points.data.distribution_points
    this.view_data = view_data.data.view_data;
    this.loader_prop = false;
    console.log(this.view_data);
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>

